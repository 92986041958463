import * as React from "react";
import "./carrier.scss";

import * as CareerComponent from "../../components/career/Career";
export default function Career() {
  return (
    <div className="Career">
      <CareerComponent.default />
    </div>
  );
}
