import * as React from "react";
import "./managment.scss";
export default function Managment() {
  const [login, setLogin] = React.useState<string>("");
  const [passwd, setPasswd] = React.useState<string>("");

  const [postName, setPostName] = React.useState<string>("");
  const [postVersion, setPostVersion] = React.useState<string>("");

  const [contentName, setContentName] = React.useState<string>("");
  const [contentType, setContentType] = React.useState<string>("");
  const [contentText, setContentText] = React.useState<string>("");

  const [result, setResult] = React.useState<string>();

  const [data, setData] = React.useState<
    Array<{ name: string; type: string; text: string }>
  >([]);

  React.useEffect(() => {}, [data, result]);
  const pushContent = async () =>
    setData([
      ...data,
      {
        name: contentName,
        type: contentType,
        text: contentText,
      },
    ]);

  const deleteContent = async () => setData(data.splice(-1));

  const sendContent = async () => {
    try {
      let content = {
        usr: login,
        passwd: passwd,
        data: [{ name: contentName, type: contentType, text: contentText }],
      };
      const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(content),
      };

      let response = await fetch(
        "http://localhost:2223/api/post/content",
        requestOptions
      );
      setResult(await response.text());
    } catch (err) {
      setResult(err);
    }
  };

  return (
    <div className="managment">
      <div className="login">
        <div className="block">
          login
          <input
            type="text"
            value={login}
            onChange={(content) => setLogin(content.target.value)}
          />
        </div>
        <div className="block">
          passwd
          <input
            type="text"
            value={passwd}
            onChange={(content) => setPasswd(content.target.value)}
          />
        </div>
      </div>
      <div className="request post">
        <div className="block">
          name
          <input
            type="text"
            value={postName}
            onChange={(content) => setPostName(content.target.value)}
          />
        </div>
        <div className="block">
          version
          <input
            type="text"
            value={postVersion}
            onChange={(content) => setPostVersion(content.target.value)}
          />
        </div>
        <button> send </button>
      </div>
      <div className="request post">
        <div className="block">
          name
          <input
            type="text"
            value={contentName}
            onChange={(content) => setContentName(content.target.value)}
          />
        </div>
        <div className="block">
          type
          <input
            type="text"
            value={contentType}
            onChange={(content) => setContentType(content.target.value)}
          />
        </div>
        <div className="block">
          text
          <input
            type="text"
            value={contentText}
            onChange={(content) => setContentText(content.target.value)}
          />
        </div>
        <button onClick={pushContent}> post </button>
        <button onClick={sendContent}> send </button>
      </div>

      {result}
      <div className="request post">{JSON.stringify(data)}</div>
    </div>
  );
}
